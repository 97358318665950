import {
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  Square,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { SpriteAnimator } from "react-sprite-animator";
import { useAppContext } from "../../contexts";
import {
  calcMulRef,
  formatNumDynDecimal,
  getName,
  roundDown,
} from "../../utils";
import ScoreTag from "../home/score";
import CopyIcon from "assets/icons/copy.svg";
import toast from "react-hot-toast";
import scoreConfig from "../../utils/scoreConfig.json";
import {useDuckImage} from "hooks/useDuckImage";
import { useTelegram } from "contexts/telegram";
const InvitePage = () => {
  const { userInfo, currentPoint, stableSize, fetchUser } = useAppContext();
  const { tgWebApp } = useTelegram();
  console.log(stableSize);

  const inviteUrl = `https://t.me/${process.env.REACT_APP_TELEGRAM_ID}?start=${userInfo?.id}`;
  useEffect(() => {
    fetchUser();
  }, []);
  const shareURLHandler = async () => {
    try {
      tgWebApp.openTelegramLink(
        `https://t.me/share/url?url=${inviteUrl}&text=${`${getName(userInfo)} invite you to play ${
          process.env.REACT_APP_NAME
        }!!!`}`
      );
      
    } catch (error) {
      console.log("Share file error", error);
    }
  };
  const copyURLHandler = async () => {
    try {
      navigator.clipboard.writeText(inviteUrl);
      toast("Copied referral link!");
    } catch (error) {
      console.log("Share file error", error);
    }
  };

   const { duckUrl, loadDuckImage } = useDuckImage(userInfo);


  const getNextRef = (refCount) => {
    for (let range of scoreConfig.refCount) {
      if (refCount < range) {
        return range;
      }
    }
  };
  const nextRefLevel = useMemo(() => {
    return getNextRef(userInfo?.refer_count);
  }, [userInfo]);
  const nextMulRef = useMemo(() => {
    return calcMulRef(nextRefLevel);
  }, [nextRefLevel]);

  return (
    <>
      <Box h="28vh" />
      <Box
        w="100%"
        h="100%"
        bg="#4ED4DD"
        display="flex"
        position="relative"
        flexDirection="column"
      >
        <Box
          top="-26vh"
          w="100%"
          h="100%"
          display="flex"
          position="absolute"
          flexDirection="column"
        >
          <ScoreTag currentPoint={currentPoint} />

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {loadDuckImage ? (
              <Center minH="230px" w="full" h="full">
                <Spinner size="xl" speed="0.25s" />
              </Center>
            ) : (
              <Square
                id="my-duck"
                transform="translateX(-2vh)"
                size="280px"
                overflow="hidden"
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  draggable: "false",
                }}
              >
                <SpriteAnimator
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    draggable: false,
                    background: "red",
                  }}
                  scale={1.5}
                  loop={true}
                  width={512}
                  height={512}
                  fps={24}
                  // IDLE
                  startFrame={0}
                  frameCount={1}
                  shouldAnimate={true}
                  sprite={duckUrl}
                />
              </Square>
            )}
          </Flex>
          <Box
            pt="2vh"
            sx={{
              marginTop: "-20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              <Text
                userSelect="none"
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                You have successfully invited
              </Text>
              <Text
                userSelect="none"
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  bg: "#FFFB49",
                  pr: "4px",
                  pl: "2px",
                  borderRadius: "4px",
                }}
              >
                {userInfo?.refer_count || 0}
              </Text>
              <Text
                userSelect="none"
                sx={{
                  color: "#00006D",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                friends
              </Text>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "30px",
                background:
                  "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.60) 50%, rgba(255, 255, 255, 0.00) 100%), #FFFDA4",
                boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
                pl: "24px",
                pr: "8px",
                py: "6px",
                w: "calc(100vw - 40px)",
                mt: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                      fontSize: stableSize?.width < 400 ? "14px" : "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    YOU
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                      fontSize: stableSize?.width < 400 ? "14px" : "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    GET
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      textShadow: "0px 3px 3px rgba(0, 0, 0, 0.20)",
                      fontSize: stableSize?.width < 400 ? "14px" : "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    EXTRA
                  </Text>
                </Box>
                <Box
                  sx={{
                    borderRadius: "40px",
                    border: "2px solid rgba(26, 6, 179, 0.40)",
                    background: "#366CB6",
                    boxShadow:
                      "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -10px 15px 0px #1A06B3 inset",
                    px: stableSize?.width < 400 ? "6px" : "10px",
                    py: stableSize?.width < 400 ? "4px" : "8px",
                    ml: "16px",
                  }}
                >
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#FFF",
                      fontSize: stableSize?.width < 400 ? "14px" : "24px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    +{roundDown(userInfo?.referalMulEarned || 0) * 100}%
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  borderRadius: "20px",
                  border: "2px solid rgba(26, 6, 179, 0.40)",
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.40) 100%)",
                  h: "100%",
                  display: "flex",
                  alignItems: "center",
                  px: stableSize?.width < 400 ? "6px" : "10px",
                  gap: stableSize?.width < 400 ? "6px" : "8px",
                }}
              >
                <AiOutlinePlus size="16px" />
                <Box>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      fontSize: stableSize?.width < 400 ? "12px" : "14px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    Invite {nextRefLevel - userInfo?.refer_count} friends
                  </Text>
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#1A06B3",
                      fontSize: stableSize?.width < 400 ? "12px" : "14px",
                      fontWeight: "300",
                      lineHeight: "normal",
                    }}
                  >
                    to BOOST +
                    {formatNumDynDecimal(roundDown(nextMulRef * 100, 2), 2)}%
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: stableSize?.width < 400 ? "8px" : "12px",
                display: "flex",
                gap: "8px",
                w: `${stableSize?.width - 40}px`,
              }}
            >
              <Box
                sx={{
                  borderRadius: "40px",
                  background: "rgba(255, 255, 255, 0.4)",
                  backgroundBlendMode: "multiply",
                  boxShadow: "0px 10px 15px 0px rgba(22, 43, 73, 0.10) inset",
                  px: "20px",
                  py: stableSize?.width < 400 ? "4px" : "8px",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  flex: 1,
                  minWidth: 0,
                }}
                onClick={copyURLHandler}
              >
                <Image w="14px" h="14px" src={CopyIcon} />
                <Text
                  userSelect="none"
                  sx={{
                    color: "#00006D",
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "normal",
                    overflow: "hidden", // Hides the overflowed text
                    textOverflow: "ellipsis", // Adds ellipsis (...) to truncated text
                    whiteSpace: "nowrap",
                  }}
                >
                  {inviteUrl}
                </Text>
              </Box>
              <Box
                sx={{
                  borderRadius: "40px",
                  border: "2px solid rgba(26, 6, 179, 0.40)",
                  background: "#366CB6",
                  boxShadow:
                    "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -10px 15px 0px #1A06B3 inset",
                  display: "flex",
                  alignItems: "center",
                  px: stableSize?.width < 400 ? "6px" : "10px",
                }}
                onClick={shareURLHandler}
              >
                <Text
                  userSelect="none"
                  sx={{
                    color: "#FFF",
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "normal",
                    whiteSpace: "nowrap",
                  }}
                >
                  Share Link
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default InvitePage;
