import {
  useIsConnectionRestored,
  useTonAddress,
  useTonWallet,
} from "@tonconnect/ui-react";
import { PREVIOUS_REFER_POINT_KEY } from "constants";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getRecoveryEnergyPoint, getRecoveryEnergyPointAutoplay } from "utils";
import { APIClient } from "../api";
import scoreConfig from "../utils/scoreConfig.json";
import toast from "react-hot-toast";
const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userToken, updatetUserToken] = useState(null);
  const [userInfo, updateUserInfo] = useState(null);
  const [currentPoint, setCurrentPoint] = useState(0);
  const [requireRegisted, setRequireRegisted] = useState(false);
  const [currentEnergy, setCurrentEnergy] = useState(0);
  const [userTaskList, setUserTaskList] = useState([]);
  const [stableSize, setStableSize] = useState(null);
  const [previousReferPoint, setPreviousReferPoint] = useState(null);
  const [appStatus, setAppStatus] = useState(null);
  const rawAddress = useTonAddress();
  const wallet = useTonWallet();
  const connectionRestored = useIsConnectionRestored();
  const [isConnectWalletTaskClicked, setIsConnectWalletTaskClicked] =
    useState(false);
  ////////////////////////////////// energy
  const [liveEnergy, setLiveEnergy] = useState(0);
  const userInfoRef = useRef(userInfo);
  const currentEnergyRef = useRef(currentEnergy);
  ///////////////////////////////////////////

  ////////////////////////////////// energy
  useEffect(() => {
    userInfoRef.current = userInfo;
    currentEnergyRef.current = currentEnergy;
  }, [userInfo, currentEnergy]);

  useEffect(() => {
    const interval = setInterval(() => {
      const lastTapTime = new Date(userInfoRef.current?.last_update);
      const currentTime = Date.now();
      const diffTime = currentTime - lastTapTime;
      let recoveryTap = 0;
      if (userInfoRef.current?.last_auto_play === null) {
        recoveryTap = getRecoveryEnergyPoint(diffTime);
      } else {
        recoveryTap = getRecoveryEnergyPointAutoplay(diffTime);
      }

      const newLiveEnergy = currentEnergyRef.current + recoveryTap;
      setLiveEnergy(
        newLiveEnergy < 0
          ? 0
          : newLiveEnergy > scoreConfig.maxEnergy
          ? scoreConfig.maxEnergy
          : newLiveEnergy
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (currentEnergy) setLiveEnergy(currentEnergy);
  }, [currentEnergy]);
  /// app status
  useEffect(() => {
    (async () => {
      const resp2 = await APIClient.getUserStatus(userToken);
      if (resp2?.success) {
        setAppStatus(resp2);
      }
    })();
  }, []);
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  /// check wallet
  useEffect(() => {
    if (rawAddress && userInfo) {
      (async () => {
        await APIClient.updateWaleltAddress(userToken, {
          address: rawAddress,
          name: wallet?.name,
          device: wallet?.device?.appName,
        });
      })();
    }
  }, [rawAddress, connectionRestored]);
  ///////////////////////////////////////////

  const telegramUserToken =
    process.env.REACT_APP_DEV_TOKEN ||
    window?.Telegram?.WebView?.initParams?.tgWebAppData;

  const { isFetching } = useQuery("fetch-user", async () => {
    if (telegramUserToken && !userToken && !userInfo) {
      const userdata = telegramUserToken;
      updatetUserToken(userdata);
      const resp = await APIClient.auth({ userToken: userdata });
      if (resp?.success == true) {
        try {
          setPreviousReferPoint(localStorage.getItem(PREVIOUS_REFER_POINT_KEY));
        } catch (error) {
          console.log(error);
        }
        await fetchUser();
        await fetchUserTaskList();
        setRequireRegisted(resp?.registration);
      }
    }
  });
  const fetchUser = async () => {
    try {
      const userdata = telegramUserToken;
      const resp = await APIClient.fetchUser({ userToken: userdata });
      if (resp?.success == true) {
        updateUserInfo(resp?.data);
        setCurrentPoint(resp?.data?.point);
        setCurrentEnergy(resp?.data?.energy);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUserTaskList = async () => {
    try {
      const userdata = telegramUserToken;
      const resp = await APIClient.fetchUserTaskList({ userToken: userdata });
      if (resp?.success == true) {
        setUserTaskList(resp?.data?.items);
        return resp?.data?.items;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!stableSize) {
      setStableSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        userToken,
        updatetUserToken,
        userInfo,
        updateUserInfo,
        isLoadingUser: isFetching,
        currentPoint,
        setCurrentPoint,
        isValidUser: userToken && userInfo,
        requireRegisted,
        fetchUser,
        currentEnergy,
        setCurrentEnergy,
        userTaskList,
        setUserTaskList,
        fetchUserTaskList,
        stableSize,
        setStableSize,
        liveEnergy,
        previousReferPoint,
        appStatus,
        isConnectWalletTaskClicked,
        setIsConnectWalletTaskClicked,
      }}
    >
      {stableSize && children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
