import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { AppContextProvider } from "./contexts";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppLayout } from "./layouts";
import { Toaster } from "react-hot-toast";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import { TelegramProvider } from "contexts/telegram";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <TonConnectUIProvider
    uiPreferences={{
      borderRadius: "s",
      // colorsSet: THEME.LIGHT,
      theme: THEME.LIGHT,
    }}
    manifestUrl="https://app.cowboyduckie.com/tonconnect-manifest.json"
    actionsConfiguration={{
      twaReturnUrl: "https://t.me/CowboyDuckieBot/app",
    }}
  >
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <TelegramProvider>
            <AppContextProvider>
              <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                  style: {
                    padding: "8px",
                    fontSize: "16px",
                    color: "#57527E",
                    borderRadius: "5px",
                    background: "#E8FDFF",
                  },
                }}
              />
              <App />
            </AppContextProvider>
          </TelegramProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ChakraProvider>
  </TonConnectUIProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
