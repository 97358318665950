import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import Countdown from "react-countdown";
import { useMutation } from "react-query";
import { AutoPlayIcon } from "theme/components/icon";
import { APIClient } from "../../api";
import { useAppContext } from "../../contexts";
import { fillNumberStr, roundDown } from "../../utils";
import scoreConfig from "../../utils/scoreConfig.json";

const AutoplayButton = ({ setIsRunning, isRunning, setIsAutoPlayMode }) => {
  const { userToken, userInfo, fetchUser } = useAppContext();
  const currentAutoplay = useMemo(() => {
    const diffInMs =
      new Date().getTime() - new Date(userInfo?.last_auto_play).getTime();

    const diffInHours = roundDown(diffInMs / (1000 * 60 * 60), 0);
    const diffInMinutes = roundDown(diffInMs / (1000 * 60), 0);
    console.log(diffInHours, diffInMinutes);
    return { diffInHours, diffInMinutes };
  }, [userInfo]);
  const endTime = new Date(
    new Date(userInfo?.last_auto_play).getTime() + scoreConfig.maxAutoplay
  );
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    console.log("completed");
    if (completed) {
      if (isRunning) setIsRunning(false);
      return (
        <Box
          sx={{
            background: "#FFFDA4",
            w: "100%",
            textAlign: "center",
            color: "#000",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          Full
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            background: "#FFFDA4",
            w: "100%",
            textAlign: "center",
            color: "#000",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "normal",
          }}
          style={{
            pointerEvents: "none",
            userSelect: "none",
            WebkitUserSelect: "none",
            WebkitTouchCallout: "none",
          }}
        >
          {fillNumberStr(hours, 2)}:{fillNumberStr(minutes, 2)}:
          {fillNumberStr(seconds, 2)}
        </Box>
      );
    }
  };
  useEffect(() => {
    if (!!userInfo?.last_auto_play) {
      setIsRunning(true);
    }
  }, [userInfo]);
  const startAutoPlayMutation = useMutation(async () => {
    setIsAutoPlayMode(true);
    const resp = await APIClient.enableAutoPlay(userToken);
    if (resp?.success == true) {
      await fetchUser();
    }
  });
  const stopAutoPlayMutation = useMutation(async () => {
    setIsRunning(false);
    const resp = await APIClient.disableAutoPlay(userToken);
    if (resp?.success == true) {
      await fetchUser();
    }
  });
  useEffect(() => {
    if (userInfo?.last_auto_play) {
      const endTime = new Date(
        new Date(userInfo?.last_auto_play).getTime() + scoreConfig.maxAutoplay
      );
      const endTime2 = new Date(new Date(userInfo?.last_auto_play).getTime());
      const currentTime = new Date();
      console.log(endTime, endTime2, currentTime);
    }
  }, [userInfo?.last_auto_play]);
  const disabledBtn =
    startAutoPlayMutation.isLoading || stopAutoPlayMutation.isLoading;
  return (
    <Box
      mx="7px"
      w="22vw"
      h="25vw"
      maxH="25vw"
      zIndex={1}
      display="flex"
      position="relative"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      background="#366CB6"
      borderRadius="20px"
      overflow="hidden"
      filter={disabledBtn ? "brightness(0.8)" : null}
      border="2px solid rgba(26, 6, 179, 0.20)"
      // boxShadow="0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)"
      onClick={async () => {
        try {
          if (disabledBtn == false) {
            if (!userInfo?.last_auto_play) {
              startAutoPlayMutation.mutate();
            } else {
              stopAutoPlayMutation.mutate();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {startAutoPlayMutation.isLoading || stopAutoPlayMutation.isLoading ? (
        <Spinner />
      ) : (
        <>
          <AutoPlayIcon w="11vw" h="10vw" />
          <Box mt="8px" sx={{ display: "flex", alignSelf: "center" }}>
            <Text
              userSelect="none"
              sx={{
                color: "#FFF",
                textAlign: "center",
                textShadow: "0px 3px 3px #162B8A",
                fontSize: "0.625rem",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              AUTOPLAY
            </Text>
          </Box>
        </>
      )}

      {userInfo?.last_auto_play && (
        <Flex w="full" position="absolute" bottom={0}>
          <Countdown date={endTime} renderer={renderer} />
        </Flex>
      )}
    </Box>
  );
};

export default AutoplayButton;
