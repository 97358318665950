import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spinner,
  Square,
  Text,
} from "@chakra-ui/react";
import { useDuckImage } from "hooks/useDuckImage";
import { useEffect, useMemo } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BiWalletAlt } from "react-icons/bi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { SpriteAnimator } from "react-sprite-animator";
import { DuckTokenIcon } from "theme/components/icon";
import { useAppContext } from "../../contexts";
import {
  addressShortener,
  formatNumDynDecimal,
  getName,
  roundDown,
  roundUp,
} from "../../utils";
import scoreConfig from "../../utils/scoreConfig.json";

import {
  useIsConnectionRestored,
  useTonAddress,
  useTonConnectModal,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import { useTelegram } from "contexts/telegram";
const LevelPage = () => {
  const { stableSize, fetchUser, userInfo } = useAppContext();
  const { state: modalState, open: openModal, close } = useTonConnectModal();
  const rawAddress = useTonAddress();
  const connector = useTonConnectUI();
  const connectionRestored = useIsConnectionRestored();
  const { tgWebApp } = useTelegram();

  useEffect(() => {
    fetchUser();
  }, []);

  const nextLevelRatio = useMemo(() => {
    if (userInfo?.level >= 0) {
      const nextLevelPoint = scoreConfig.level[userInfo?.level];
      console.log(+userInfo?.point, +nextLevelPoint);
      return userInfo?.point / nextLevelPoint;
    } else return 0;
  }, [userInfo]);

  const { duckUrl, loadDuckImage } = useDuckImage(userInfo);

  return (
    <>
      <Box h={stableSize?.height < 700 ? "18vh" : "20vh"} />
      <Box
        w="100%"
        h="100%"
        bg="#4ED4DD"
        display="flex"
        position="relative"
        flexDirection="column"
      >
        <Box
          top={stableSize?.height < 700 ? "-30vh" : "-28vh"}
          w="100%"
          h="100%"
          display="flex"
          position="absolute"
          flexDirection="column"
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {loadDuckImage ? (
              <Center minH="230px" w="full" h="full">
                <Spinner size="xl" speed="0.25s" />
              </Center>
            ) : (
              <Square
                id="my-duck"
                transform="translateX(-2vh)"
                size="280px"
                overflow="hidden"
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  draggable: "false",
                }}
              >
                <SpriteAnimator
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    draggable: false,
                    background: "red",
                  }}
                  scale={stableSize?.height < 700 ? 2.2 : 2}
                  loop={true}
                  width={400}
                  height={400}
                  fps={24}
                  // IDLE
                  startFrame={0}
                  frameCount={1}
                  shouldAnimate={true}
                  sprite={duckUrl}
                />
              </Square>
            )}
          </Flex>
          <Box
            sx={{
              marginTop: stableSize?.height < 700 ? "-28px" : "-20px",
              display: "flex",
              flexDirection: "column",
              px: "40px",
            }}
          >
            <Flex>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  borderRadius: "40px",
                  border: "2px solid rgba(26, 6, 179, 0.40)",
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 103, 0.10) 0%, rgba(26, 6, 179, 0.00) 100%)",
                  // mb: index < rankList?.length ? "10px" : 0,
                  // boxShadow: "0px -5px 20px 0px #FFF",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "40px",
                    background: "#366CB6",
                    boxShadow: "0px -5px 10px 0px #1A06B3 inset",
                    w: "28px",
                    h: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiOutlineUser color="#FFF" />
                  {/* <Image
                    src="assets/taskicon.png"
                    sx={{
                      w: "20px",
                      h: "20px",
                      filter: "drop-shadow(0px 5px 5px #0A0285)",
                      objectFit: "contain",
                    }}
                    _active={{}}
                  /> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    pl: "4px",
                    alignItems: "center",
                    // flexDirection: "column",
                  }}
                >
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#00006D",
                      fontSize: "12px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      pl: "8px",
                      // textAlign: "center",
                    }}
                  >
                    {getName(userInfo)}
                  </Text>
                </Box>
                <Box
                  sx={{
                    borderRadius: "40px",
                    background: "#366CB6",
                    boxShadow: "0px -10px 15px 0px #1A06B3 inset",
                    display: "flex",
                    alignItems: "center",
                    minW: "100px",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#FFF",
                      fontSize: "12px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    Level {userInfo?.level + 1}
                  </Text>
                </Box>
              </Box>
            </Flex>
            {rawAddress ? (
              <Box
                sx={{
                  borderRadius: "12px",
                  background: "#0098EA",
                  py: "8px",
                  mt: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#FFF",
                  transition: "all 0.2s ease",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  position: "relative",
                }}
                _active={{
                  background: "#007ABC",
                }}
                onClick={() => {
                  try {
                    tgWebApp.showPopup(
                      {
                        title: "Logout",
                        message: "Disconnect your wallet",
                        buttons: [
                          {
                            id: "logout-op-id",
                            type: "destructive",
                            text: "Logout",
                          },
                          {
                            id: "cancel-op-id",
                            type: "default",
                            text: "Cancel",
                          },
                        ],
                      },
                      (buttonId) => {
                        if (rawAddress && buttonId == "logout-op-id")
                          connector[0].connector.disconnect();
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {!connectionRestored ? (
                  <Spinner size="sm" />
                ) : (
                  <>
                    <BiWalletAlt color="#FFF" />
                    {addressShortener(rawAddress)}
                    <Box
                      sx={{
                        position: "absolute",
                        right: "8px",
                      }}
                    >
                      <HiOutlineDotsVertical color="#FFF" />
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  borderRadius: "12px",
                  background: "#0098EA",
                  py: "8px",
                  mt: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#FFF",
                  transition: "all 0.2s ease",
                }}
                _active={{
                  background: "#007ABC",
                }}
                onClick={() => {
                  if (modalState.status == "closed" && connectionRestored)
                    openModal();
                }}
              >
                {!connectionRestored ? (
                  <Spinner size="sm" />
                ) : (
                  <>Connect Wallet</>
                )}
              </Box>
            )}
            <Box
              sx={{
                borderRadius: "12px",
                background:
                  "linear-gradient(270deg, #FFFDA4 0%, #FFFED9 49.67%, #FFFDA4 100%)",
                px: "8px",
                pt: "8px",
                pb: "10px",
                mt: "8px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box display="flex" alignItems="center">
                  <Text
                    sx={{
                      color: "#006CFF",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {formatNumDynDecimal(roundDown(userInfo?.point, 2), 2)}
                  </Text>
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    /
                    {formatNumDynDecimal(
                      roundUp(scoreConfig.level?.[userInfo?.level], 0),
                      2
                    )}{" "}
                  </Text>
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    to Level {userInfo?.level + 2}
                  </Text>
                </Box>
                <Box
                  sx={{
                    borderRadius: "22px",
                    background: "#68A4F6",
                    // boxShadow: "0px 5px 5px 0px #204199 inset",
                    w: `${stableSize?.width - 96}px`,
                    h: "2vh",
                    mt: "6px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "22px",
                      background: "#1A06B3",
                      boxShadow: "0px -5px 8px 0px #4838C2 inset",
                      width: `${(stableSize?.width - 96) * nextLevelRatio}px`,
                      h: "2vh",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box sx={{ mt: "8px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    Game Rewards
                  </Text>
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    {formatNumDynDecimal(roundDown(userInfo?.point, 2), 2)}
                    <DuckTokenIcon width="20px" height="20px" />
                  </Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    Referral Rewards
                  </Text>
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    {formatNumDynDecimal(
                      roundDown(userInfo?.refer_point, 2),
                      2
                    )}
                    <DuckTokenIcon width="20px" height="20px" />
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{ borderTop: "2px solid #68A4F6", mt: "8px", pt: "8px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    Total
                  </Text>
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    {formatNumDynDecimal(
                      roundDown(userInfo?.point + userInfo?.refer_point, 2),
                      2
                    )}
                    <DuckTokenIcon width="20px" height="20px" />
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "12px",
                background:
                  "linear-gradient(270deg, #FFFDA4 0%, #FFFED9 49.67%, #FFFDA4 100%)",
                px: "8px",
                pt: "8px",
                pb: "10px",
                mt: "6px",
              }}
            >
              <Box sx={{}}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    Ton rewards
                  </Text>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <Text
                      sx={{
                        color: "#00006D",
                        // fontSize: "10px",
                        fontWeight: "700",
                        lineHeight: "normal",
                        ml: "4px",
                      }}
                    >
                      0
                    </Text>
                    <Image
                      src="assets/ton_symbol.png"
                      mt="1px"
                      w="16px"
                      h="16px"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    Usdt Rewards
                  </Text>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <Text
                      sx={{
                        color: "#00006D",
                        // fontSize: "10px",
                        fontWeight: "700",
                        lineHeight: "normal",
                        ml: "4px",
                      }}
                    >
                      0
                    </Text>
                    <Image src="assets/usdt.png" mt="1px" w="16px" h="16px" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      color: "#00006D",
                      // fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "normal",
                      ml: "4px",
                    }}
                  >
                    Azero rewards
                  </Text>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <Text
                      sx={{
                        color: "#00006D",
                        // fontSize: "10px",
                        fontWeight: "700",
                        lineHeight: "normal",
                        ml: "4px",
                      }}
                    >
                      0
                    </Text>
                    <Image
                      src="assets/alephzero.png"
                      mt="1px"
                      w="16px"
                      h="16px"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                mt: "8px",
                gap: "8px",
              }}
            >
              <Button
                flex={1}
                size="sm"
                onClick={() => {
                  utils.openLink("https://telegram.org/tos/mini-apps", {
                    tryBrowser: true,
                  });
                }}
              >
                Terms of use
              </Button>
              <Button
                flex={1}
                size="sm"
                onClick={() => {
                  utils.openLink("https://telegram.org/privacy-tpa", {
                    tryBrowser: true,
                  });
                }}
              >
                Privacy policy
              </Button>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LevelPage;
