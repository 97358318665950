import { Box, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { formatNumDynDecimal, roundDown } from "../../utils";
import { useAppContext } from "../../contexts";
import { DuckTokenIcon } from "../../theme/components/icon";
import scoreConfig from "utils/scoreConfig.json";
import { useHistory } from "react-router-dom";

const ScoreTag = ({ sx, currentPoint }) => {
  const history = useHistory();
  const { stableSize, userInfo } = useAppContext();
  const [livePoint, setLivePoint] = useState(0);
  const userInfoRef = useRef(userInfo);
  const currentEnergyRef = useRef(currentPoint);
  useEffect(() => {
    userInfoRef.current = userInfo;
    currentEnergyRef.current = currentPoint;
  }, [userInfo, currentPoint]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        userInfoRef?.current?.mul_rate &&
        userInfoRef.current?.last_auto_play &&
        userInfoRef.current?.point
      ) {
        const lastAutoPlay = new Date(userInfoRef.current?.last_auto_play);
        const currentTime = Date.now();
        let diff = currentTime - lastAutoPlay;
        if (diff > scoreConfig.maxAutoplay) diff = scoreConfig.maxAutoplay;
        const basePoint =
          (diff / (1000 * 60 * 60)) * scoreConfig.autoPlayRewardPerHour;
        const userPoint =
          (userInfoRef.current?.point || 0) +
          basePoint * userInfoRef?.current?.mul_rate * scoreConfig.autoPlayRate;
        setLivePoint(userPoint + +(userInfoRef.current?.refer_point || 0));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (currentPoint) {
      setLivePoint(
        (currentPoint || 0) + +(userInfoRef.current?.refer_point || 0)
      );
    }
  }, [currentPoint]);
  return (
    <Box
      sx={{
        border: "2px solid rgba(26, 6, 179, 0.40)",
        borderRadius: "40px",
        background: "#366CB6",
        boxShadow:
          "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -10px 15px 0px #1A06B3 inset",
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        px: "20px",
        py: stableSize?.width < 400 ? "4px" : "6px",
        gap: "10px",
        ...sx,
        zIndex: 10,
      }}
      onClick={() => {
        history.push("/level");
      }}
    >
      <Text
        userSelect="none"
        sx={{
          color: "#FFF",
          // fontFamily: "Inter",
          fontSize: stableSize?.width < 400 ? "16px" : "20px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "normal",
        }}
      >
        {!!userInfo?.last_auto_play
          ? formatNumDynDecimal(roundDown(livePoint, 2), 2)
          : formatNumDynDecimal(roundDown(livePoint, 2), 2)}
      </Text>

      <DuckTokenIcon width="3vh" height="3vh" />
    </Box>
  );
};
export default ScoreTag;
