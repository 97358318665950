import { Box, Button, Text } from "@chakra-ui/react";

const AccountPage = () => {
  return (
    <Box
      sx={{
        w: "100%",
        h: "100%",
        display: "flex",
        flexDirection: "column-reverse",
        p: "10px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button>Connect</Button>
          <Box>address 0xdasdasd....</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "20px",
            justifyContent: "space-between",
            px: "20px",
          }}
        >
          <Box>
            <Text userSelect="none">Premium account</Text>
            <Text userSelect="none">Days of active</Text>
            <Text userSelect="none">Invited 55 friends</Text>
            <Text userSelect="none">Levels 12</Text>
            <Text userSelect="none">Boost</Text>
          </Box>
          <Box>
            <Text userSelect="none">+3%</Text>
            <Text userSelect="none">+1.5%</Text>
            <Text userSelect="none">+2.5%</Text>
            <Text userSelect="none">+1.2%</Text>
            <Text userSelect="none">+400%</Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "20px",
            justifyContent: "space-between",
            px: "20px",
            borderTopWidth: "2px",
            borderTopColor: "gray",
          }}
        >
          <Box>
            <Text userSelect="none">Total boost</Text>
          </Box>
          <Box>
            <Text userSelect="none">+408.2%</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AccountPage;
