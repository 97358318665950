import StatusAdminPage from "pages/admin/status";
import Platform from "pages/start/platform";
import { HashRouter, Route, Switch } from "react-router-dom";
import { CoreLayout, DefaultLayout } from "./layouts";
import AccountPage from "./pages/account";
import HomePage from "./pages/home";
import InvitePage from "./pages/invite";
import Leaderboard from "./pages/leaderboard";
import LevelPage from "./pages/levels";
import ScorePage from "./pages/score";
import StartPage from "./pages/start";
import TaskPage from "./pages/tasks";
import MenuAdminPage from "pages/admin/menu";
import LeaderboardAdmin from "pages/leaderboardAdmin";

// Component to render a route with a layout
const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

function App() {
  return (
    <HashRouter>
      <Switch>
        <RouteWithLayout
          exact
          path="/"
          layout={DefaultLayout}
          component={StartPage}
        />
        <RouteWithLayout
          exact
          path="/account"
          layout={CoreLayout}
          component={AccountPage}
        />
        <RouteWithLayout
          exact
          path="/level"
          layout={CoreLayout}
          component={LevelPage}
        />
        <RouteWithLayout
          exact
          path="/score"
          layout={CoreLayout}
          component={ScorePage}
        />
        <RouteWithLayout
          exact
          path="/invite"
          layout={CoreLayout}
          component={InvitePage}
        />
        <RouteWithLayout
          exact
          path="/tasks"
          layout={CoreLayout}
          component={TaskPage}
        />
        <RouteWithLayout
          exact
          path="/leaderboard"
          layout={CoreLayout}
          component={Leaderboard}
        />
        <RouteWithLayout
          exact
          path="/app"
          layout={CoreLayout}
          component={HomePage}
        />
        <RouteWithLayout
          exact
          path="/admin/status"
          layout={DefaultLayout}
          component={StatusAdminPage}
        />
        <RouteWithLayout
          exact
          path="/admin/leaderboard"
          layout={DefaultLayout}
          component={LeaderboardAdmin}
        />
        <RouteWithLayout
          exact
          path="/admin"
          layout={DefaultLayout}
          component={MenuAdminPage}
        />
        <RouteWithLayout
          exact
          path="/platform"
          layout={DefaultLayout}
          component={Platform}
        />
      </Switch>
    </HashRouter>
  );
}

export default App;
