import { useEffect, useState } from "react";
import { preloadImage } from "utils/preloadImage";

export const useDuckImage = (userInfo) => {
  const [duckUrl, setDuckUrl] = useState("");
  const [loadDuckImage, setLoadDuckImage] = useState(true);

  useEffect(() => {
    let url = "./assets/level1/texture1.png";
    if (userInfo?.level) {
      const level = userInfo.level;
      switch (true) {
        case level < 5:
          url = `./assets/level1/texture1.png`;
          break;
        case level >= 5 && level < 10:
          url = `./assets/level1/texture2.png`;
          break;
        case level >= 10 && level < 15:
          url = `./assets/level1/texture3.png`;
          break;
        case level >= 15:
          url = `./assets/level1/texture4.png`;
          break;
        default:
          url = `./assets/level1/texture1.png`;
          break;
      }
    }
    preloadImage(url)
      .then(() => {
        console.log(userInfo.level);
        console.log(url);
        setDuckUrl(url);
        setLoadDuckImage(false);
      })
      .catch((err) => console.error("Error loading image:", err));
  }, [userInfo]);

  return { duckUrl, loadDuckImage };
};
