import { Box, Button, Input, SimpleGrid, Text } from "@chakra-ui/react";
import { APIClient } from "api";
import { useAppContext } from "contexts";
import { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { roundDown } from "utils";
import { formatNumDynDecimal } from "utils";
import { getName } from "utils";

const MenuAdminPage = () => {
  const { userToken } = useAppContext();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState(null);
  return (
    <Box
      sx={{
        p: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button size="sm" onClick={() => history.push("/admin/status")}>
        App Status
      </Button>
      <Button mt="4px" size="sm" onClick={() => history.push("/admin/leaderboard")}>
        Ranking
      </Button>
      <Box
        sx={{
          mt: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          placeholder="ID"
          size="sm"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <Button
          sx={{ mt: "8px" }}
          size="sm"
          onClick={async () => {
            try {
              const resp = await APIClient.getUserAdmin(userToken, search);
              if (resp?.success == true) setUserData(resp?.data);
            } catch (error) {
              toast.error("Error");
            }
          }}
        >
          Search
        </Button>
        {userData && (
          <Box>
            <SimpleGrid columns={2}>
              <Text>ID</Text>
              <Text>{userData?.id}</Text>
              <Text>name</Text>
              <Text> {getName(userData)}</Text>
              <Text>Username</Text>
              <Text>{userData?.username}</Text>
              <Text>point</Text>
              <Text>
                {formatNumDynDecimal(roundDown(userData?.point, 2), 2)}
              </Text>
              <Text>ref point</Text>
              <Text>
                {formatNumDynDecimal(roundDown(userData?.refer_point, 2), 2)}
              </Text>
              <Text>ref count</Text>
              <Text>
                {formatNumDynDecimal(roundDown(userData?.refer_count, 2), 2)}
              </Text>
              <Text>age</Text>
              <Text>{userData?.age}</Text>
              <Text>mulrate</Text>
              <Text>{userData?.mul_rate}</Text>
            </SimpleGrid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MenuAdminPage;
