import React, { useEffect, useMemo } from "react";
import { Box, Button, calc, Flex, Image, Text } from "@chakra-ui/react";
import { useAppContext } from "../contexts";
import { useHistory } from "react-router-dom";
import scoreConfig from "../utils/scoreConfig.json";
import {
  AddNewIcon,
  HomeIcon,
  LeaderBoardIcon,
  TestIcon,
} from "../theme/components/icon";
import { FiUser } from "react-icons/fi";

export const AppLayout = ({ children }) => {
  useEffect(() => {
    try {
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  return <>{children}</>;
};

export const DefaultLayout = ({ children }) => {
  const { stableSize } = useAppContext();
  const history = useHistory();
  if (
    !(
      ["ios", "android", "tdesktop"]?.includes(
        window?.Telegram?.WebView?.initParams?.tgWebAppPlatform
      ) || process.env.REACT_APP_DEV_TOKEN
    ) &&
    !["/platform", "/admin/status", "/admin", "/admin/leaderboard"].includes(
      history?.location?.pathname
    )
  ) {
    console.log("DefaultLayout", history);
    history?.push("/platform");
  }
  useEffect(() => {
    if (window?.Telegram?.WebApp) {
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setBackgroundColor("#FFF");
      window.Telegram.WebApp.setHeaderColor("#FFF");
    }
  }, [window]);
  return (
    <Box
      backgroundImage="assets/bg-02.png"
      backgroundSize="cover"
      h={`${stableSize?.height}px`}
      w="100%"
    >
      {children}
    </Box>
  );
};
export const CoreLayout = ({ children }) => {
  const { userInfo, stableSize } = useAppContext();
  const history = useHistory();
  const currentPath = history?.location?.pathname;
  if (
    !(
      ["ios", "android", "tdesktop"]?.includes(
        window?.Telegram?.WebView?.initParams?.tgWebAppPlatform
      ) || process.env.REACT_APP_DEV_TOKEN
    ) &&
    !["/platform", "/admin/status", "/admin", "/admin/leaderboard"].includes(
      currentPath
    )
  ) {
    console.log("DefaultLayout", history);
    history?.push("/platform");
  }
  useEffect(() => {
    if (window?.Telegram?.WebApp) {
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setBackgroundColor("#FFF");
      window.Telegram.WebApp.setHeaderColor("#FFF");
    }
  }, [window]);
  const username = `${userInfo?.first_name} ${userInfo?.last_name}`;
  const nextLevelRatio = useMemo(() => {
    if (userInfo?.level >= 0) {
      const nextLevelPoint = scoreConfig.level[userInfo?.level];
      return userInfo?.point / nextLevelPoint;
    } else return 0;
  }, [userInfo]);
  const isLevelPage = currentPath == "/level";
  return (
    <Box
      display="flex"
      flexDirection="column"
      h={stableSize.height}
      w={stableSize.width}
      bg={
        currentPath === "/tasks" || ["/leaderboard"].includes(currentPath)
          ? "#4ED4DD"
          : "#FFFDA4"
      }
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Flex
          maxHeight="15vh"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          borderRadius="0px 0px 40px 40px"
          background="linear-gradient(180deg, rgba(54, 108, 182, 0.00) 0%, rgba(54, 108, 182, 0.30) 66%, rgba(54, 108, 182, 0.60) 100%), #F9F9F9"
          backgroundSize="cover"
          onClick={() => {
            history.push("/level");
          }}
        >
          <Text
            userSelect="none"
            h="3vh"
            mb="12px"
            fontSize="1.5rem"
            fontWeight="bold"
          >
            Hi, {username}
          </Text>
          <Flex
            mb="12px"
            justifyContent="center"
            alignItems="center"
            // h="6vh"
            minH="3vh"
            w="72vw"
            mt="4px"
            py="8px"
            sx={{
              borderRadius: "22px",
              background: "#FFF",
              boxShadow: "0px 10px 15px 0px rgba(22, 43, 73, 0.20) inset",
              h: isLevelPage ? "16px" : null,
            }}
          >
            <>
              <Text
                userSelect="none"
                minW="60px"
                w="fit-content"
                color="#1A06B3"
                fontWeight="bold"
                fontSize="0.75rem"
                sx={{
                  opacity: isLevelPage ? 0 : 1,
                  transition: "all 0.1s ease-in-out",
                }}
              >
                Level {userInfo?.level + 1 || 0}
              </Text>
              <Box
                sx={{
                  borderRadius: "22px",
                  background: "#366CB6",
                  boxShadow: "0px 5px 5px 0px #204199 inset",
                  w: "50vw",
                  h: "2vh",
                  opacity: isLevelPage ? 0 : 1,
                  transition: "all 0.1s ease-in-out",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "22px",
                    background: "#1A06B3",
                    boxShadow: "0px -5px 8px 0px #4838C2 inset",
                    width: `calc(50vw * ${nextLevelRatio})`,
                    h: "2vh",
                    opacity: isLevelPage ? 0 : 1,
                    transition: "all 0.1s ease-in-out",
                  }}
                ></Box>
              </Box>
            </>
          </Flex>
        </Flex>
        <Box flex={1}>{children}</Box>
      </Box>
      <Box minH="13vh" bg="#4ED4DD" zIndex={10}>
        <Flex
          borderRadius="40px 40px 0 0 "
          bg="#F9F9F9"
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
        >
          {[
            {
              path: "/app",
              icon: <HomeIcon width="4vh" height="4vh" />,
              name: "Home",
            },
            {
              path: "/level",
              icon: (
                <FiUser size="3.6vh" width="8vh" height="8vh" color="#FFF" />
              ),
              name: "Profile",
            },
            {
              path: "/invite",
              icon: <AddNewIcon width="4vh" height="4vh" />,
              name: "Share",
            },
            {
              path: "/tasks",
              icon: <TestIcon width="4vh" height="4vh" />,
              name: "Tasks",
            },
            {
              path: "/leaderboard",
              icon: <LeaderBoardIcon width="4vh" height="4vh" />,
              name: "Rank",
            },
          ].map((e) => {
            const isActive = e.path == currentPath;
            return (
              <Box
                mx="7px"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: isActive ? "#366CB6" : "#4ED4DD",
                  boxShadow: isActive ? "inset 0px 10px 15px #204199" : null,
                  minW: "7.6vh",
                  minH: "7.6vh",
                  borderRadius: "20px",
                  transition: "all 0.4s ease",
                  position: "relative",
                  pb: "10px",
                }}
                onClick={() => history.push(e.path)}
              >
                {e.icon}
                <Text
                  sx={{
                    color: "#FFF",
                    position: "absolute",
                    bottom: "2px",
                    fontSize: "12px",
                  }}
                >
                  {e.name}
                </Text>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
};
