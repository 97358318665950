import { Box, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../contexts";
import { roundDown } from "../../utils";
import scoreConfig from "../../utils/scoreConfig.json";
import { PowerIcon } from "../../theme/components/icon";
const EnergyBar = () => {
  const { userInfo, currentEnergy, stableSize, liveEnergy } = useAppContext();

  const energyRate = roundDown(liveEnergy, 4) / scoreConfig.maxEnergy;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "20px",
        top: "calc(100vh / 4)",
        zIndex: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PowerIcon width="1.5rem" height="1.5rem" />
        {/* <Text userSelect='none'            sx={{
            color: "#1A06B3",
            textShadow: "0px 3px 3px rgba(0, 0, 0, 0.10)",
            fontSize: "1.25rem",
            fontWeight: "700",
            lineHeight: "normal",
            mb: "8px",
          }}
        >
          {roundDown(liveEnergy, 0)}
        </Text> */}
      </Box>
      <Box
        overflow="hidden"
        sx={{
          h: "calc(25vh + 4px)",
          w: "3vh",
          borderRadius: "22px",
          border: "2px solid rgba(26, 6, 179, 0.40)",
          background: "#366CB6",
          boxShadow:
            "10px 10px 10px 0px #204199 inset, 0px 3px 3px 0px rgba(0, 0, 0, 0.10)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            h: `${energyRate * 25}vh`,
            w: "full",
            background:
              energyRate > 0.5
                ? "#49FF49"
                : energyRate > 0.1
                ? "#FFFB49"
                : "#FF4949",
            boxShadow: "0px -5px 8px 0px #060 inset",
          }}
        >
          <Box
            sx={{
              h: `${energyRate * 25}vh`,
              w: "full",
              bg: "#FFF",
              opacity: "0.6",
              mixBlendMode: "overlay",
              filter: "blur(2.5px)",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EnergyBar;
