import { Box, Text } from "@chakra-ui/react";
import { APIClient } from "api";
import { useAppContext } from "contexts";
import { useEffect, useState } from "react";
import { roundDown } from "utils";
import { formatNumDynDecimal } from "utils";
import { useHistory } from "react-router-dom";

const StatusAdminPage = () => {
  const history = useHistory();
  const { userToken, userInfo, stableSize } = useAppContext();
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      const resp2 = await APIClient.getUserStatus(userToken);
      if (resp2?.success) {
        setData(resp2);
      }
    })();
  }, []);

  return (
    <Box p="20px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>Total user</Text>
        <Text>{data?.totalUser}</Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>Play point</Text>
        <Text>{formatNumDynDecimal(roundDown(data?.totalPoints, 2), 2)}</Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>ref point</Text>
        <Text>
          {formatNumDynDecimal(roundDown(data?.totalPointsRefer, 2), 2)}
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>Total point</Text>
        <Text>{formatNumDynDecimal(roundDown(data?.total, 2), 2)}</Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "20px",
        }}
      >
        <Text>Connect X</Text>
        <Text>{formatNumDynDecimal(roundDown(data?.counttw, 2), 2)}</Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>Follow X tweet</Text>
        <Text>{formatNumDynDecimal(roundDown(data?.countFollowTw, 2), 2)}</Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>Join Group tele</Text>
        <Text>{formatNumDynDecimal(roundDown(data?.countJoinGr, 2), 2)}</Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text>Connnect Wallet</Text>
        <Text>{formatNumDynDecimal(roundDown(data?.countConnectWallet, 2), 2)}</Text>
      </Box>
    </Box>
  );
};

export default StatusAdminPage;
