import { Box, Button, Image as ImageChakra, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import { roundDown } from "utils";
import { DuckTokenIcon } from "theme/components/icon";
const Platform = () => {
  const { stableSize } = useAppContext();
  return (
    <Box
      w={`${stableSize?.width}px`}
      h={`${stableSize?.height}px`}
      display="flex"
      flexDirection="column"
      backgroundImage="assets/bg-02.png"
      //   h={`${stableSize.height}px`}
      //   w="100%"
      justifyContent="center"
      alignItems="center"
    >
      <ImageChakra
        w={`${stableSize?.width / 1.5}px`}
        h={`${stableSize?.width / 1.5}px`}
        src="assets/welcome-sticker-02.png"
        objectFit="contain"
      />
      <Text
        sx={{
          color: "#00006D",
          textAlign: "center",
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "normal",
        }}
      >
        This game is
      </Text>
      <Text
        sx={{
          color: "#00006D",
          textAlign: "center",
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "normal",
        }}
      >
        mobile-only
      </Text>
    </Box>
  );
};

export default Platform;
